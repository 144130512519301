import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n';
import VCalendar from 'v-calendar';

Vue.config.productionTip = false

import Notify from 'mdbvue/lib/components/Notify'

import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import { faPlus, faCartShopping, faCalendar, faPen, faUndo, faClock, faMinus, faPeopleGroup, faExclamationCircle,
   faTicketAlt, faAngleDoubleRight, faUser, faCircleCheck, faBars, faTimesCircle, faPhone, faMousePointer, faCircle,
   faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import { faInstagramSquare, faTwitterSquare, faFacebookSquare, faLinkedin, 
    faYoutubeSquare, faGooglePlusSquare } from '@fortawesome/free-brands-svg-icons'

import DefaultLayout from '@/layouts/DefaultLayout.vue';

import * as es from './i18n/es.json'
import * as en from './i18n/en.json'
import * as fr from './i18n/fr.json'

import './styles/CustomStyle.css';

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// // Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import * as mdbvue from 'mdbvue'
import { far } from '@fortawesome/free-regular-svg-icons'
for (const component in mdbvue) {
  Vue.component(component, mdbvue[component])
}


library.add(faInstagramSquare, faTwitterSquare, faFacebookSquare, faLinkedin, faYoutubeSquare, faExclamationCircle, faAngleDoubleRight,
  faGooglePlusSquare, faPlus, faCartShopping, faCalendar, faPen, faUndo, faClock, faMinus, faPeopleGroup, faTicketAlt, faUser, faCircleCheck, 
  faBars, faTimesCircle, faPhone, faMousePointer, faCircle, far, faQuestionCircle );

Vue.use(Notify)

Vue.component('default-layout', DefaultLayout);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.component('font-awesome-layers', FontAwesomeLayers);

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Vue.use(VueI18n);
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

const messages = {
  'es': {...es},
  'en': {...en},
  'fr': {...fr},
}

const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
