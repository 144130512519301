<template>
  <div class="margin-malaga-container">

    <carousel :autoplay="true" :perPage="1" class="margin-malaga-container" :loop="true" :autoplayTimeout="3000" :paginationEnabled="false">
      <slide>
        <div>
          <img src="@/assets/1.jpg" class="d-block mx-auto" style="max-height: 350px; width: auto; max-width: 100vw !important;"/>
        </div>
      </slide>
      <slide>
        <div>
          <img src="@/assets/2.jpg" class="d-block mx-auto" style="max-height: 350px; width: auto; max-width: 100vw !important;"/>
        </div>
      </slide>

    </carousel>

    <mdb-container>
      <mdb-jumbotron>
        <h5 class="font-weight-bold">{{ $t('default.JUMBOTRON_MESSAGE') }}</h5>
      </mdb-jumbotron>
    </mdb-container>

    <hr>
    <div class="mt-5 d-flex justify-content-center">
      <mdb-card-group class="justify-content-center">
        <productos-card v-for="(producto, index) in productos" 
            :key="index" 
            :visita="producto.nombres[idiomaIndex].texto"
            :horarios="producto.horarios[idiomaIndex].texto"
            :precio="producto.precioOrientativo"
            :descripcion="producto.descripciones[idiomaIndex].texto"
            :banner="`${imagenesUrl}/visitas_castillos/${producto.oldId}/${producto.banner}`"
            :productoId="producto.oldId"
            :descuentos="producto.descuentos[idiomaIndex].texto"
            @info="abrirDetalles"
            @comprar="goToPurchase"
            @descuentos="abrirDescuentos"
            :documento="producto.documentos"
            >
          </productos-card> 
      </mdb-card-group> 
    </div>

    <descuentos-component 
      :mostrar="mostrarDescuentos" 
      @comprar="( evt ) => $router.push(`/reservarEntradas?idProducto=${evt.productId}`)"
      @close="() => mostrarDescuentos=false"
      :titulo="detallesInfo.visita"
      :htmlText="detallesInfo.descuentos"
      :productoId="detallesInfo.productoId"
    />

    <details-component 
      :detalles="mostrarDetalles" 
      @comprar="( evt ) => $router.push(`/reservarEntradas?idProducto=${evt.productId}`)"
      @close="() => mostrarDetalles=false"
      :titulo="detallesInfo.visita"
      :horarios="detallesInfo.horarios"
      :htmlText="detallesInfo.detalles"
      :productoId="detallesInfo.productoId"
    />

    <mdb-modal :show="popupStart" @close="() => popupStart = false" centered>
      <mdb-modal-body class="unique-color">
        <div class="py-5 px-3">
          <h3 class="font-weight-bold text-uppercase text-light">{{ welcomeTitle }}</h3>
          <hr class="red unique-color-dark"/>
          <h5 class="text-light ont-weight-bold text-uppercase">{{ welcomeText }}</h5>
          <mdb-btn class="mt-5" color="primary" size="lg" @click=" () => popupStart = false">{{ $t('default.BUTTONS_ACCEPT') }}</mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import ApiService from '@/services/ApiService';
import ProductosCard from '@/components/ProductosCard.vue';
import DetailsComponent from '@/components/DetailsComponent.vue';
import DescuentosComponent from '@/components/DescuentosComponent.vue'
import { Carousel, Slide} from 'vue-carousel';
export default {
  name: 'HomeView',
  components: {
    ProductosCard,
    DetailsComponent,
    DescuentosComponent,
    Carousel,
    Slide
  },
  data(){
    return{
      productos: [],
      imagenesUrl: "",
      mostrarDetalles: false,
      mostrarDescuentos: false,
      
      detallesInfo: {
        visita: null,
        horarios: null,
        detalles: null,
        productoId: null,
        descuentos: null
      },
      carouselItems: [
        { src: 'https://mdbootstrap.com/img/Photos/Slides/img%20(68).webp',
          alt: "...",
          label: "First slide label",
          caption: "Nulla vitae elit libero, a pharetra augue mollis interdum."
        },
        { 
          src: 'https://mdbootstrap.com/img/Photos/Slides/img%20(68).webp',
          alt: "...",
          label: "First slide label",
          caption: "Nulla vitae elit libero, a pharetra augue mollis interdum."
        },
        { 
          src: 'https://mdbootstrap.com/img/Photos/Slides/img%20(68).webp',
          alt: "...",
          label: "First slide label",
          caption: "Nulla vitae elit libero, a pharetra augue mollis interdum."
        }
      ],
      popupStart: false,
      welcomeTitle: null,
      welcomeText: null,
    }
  },
  methods:{
    goToPurchase( evt ){
      console.log(evt);
      const { productoId, visita } = evt;
      const visitaUrl = encodeURI( visita );

      this.$router.push(`/reservarEntradas?idProducto=${productoId}`)

    },
    abrirDetalles( evt ){

      this.detallesInfo.visita = evt.titulo;
      this.detallesInfo.horarios = evt.horarios;
      this.detallesInfo.detalles = evt.descripcion;
      this.detallesInfo.productoId = evt.productoId;
      this.mostrarDetalles = true;
    },
    abrirDescuentos( evt ){
      this.detallesInfo.descuentos = evt.descuentos;
      this.detallesInfo.productoId = evt.productoId;

      this.detallesInfo.visita = evt.titulo;
      this.mostrarDescuentos = true;
    }
  },
  async created(){
    this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;

    const idioma = this.$store.getters.getIdioma ?? 'es';
    const welcomeResult = await ApiService.getWelcome( idioma, 'Alcazaba y Castillo de Gibralfaro');

    if( welcomeResult.data.welcomeActive && this.$route.query.home == null ){
      this.popupStart = true;
      this.welcomeTitle = welcomeResult.data.header;
      this.welcomeText = welcomeResult.data.subtitle;
    }
    else{
      this.popupStart = false;
    }

    const result = await ApiService.getVisitas( idioma, 'Alcazaba y Castillo de Gibralfaro', 'Tarifa' )
    this.productos = result.data;

    console.log( this.productos );

  },
  computed:{
    idiomaIndex(){
      const idioma = this.$store.getters.getIdioma ?? 'es';

      if( idioma == 'es' || idioma == null){
        return 0;
      }
      else if( idioma == 'en' ){
        return 1;
      }
      else if( idioma == 'fr' ){
        return 2
      }
      else if( idioma == 'de' ){
        return 3;
      }

    }
  }
}
</script>

<style scoped>
  .view{
    background-size: cover;
    height: 100%;
  }

  .title{
    font-weight: 900 !important;
  }
</style>
