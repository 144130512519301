import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';
import { v4 as uuidv4 } from 'uuid';

import ApiService from './services/ApiService'

import moment from "moment"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    cartUuid: state.cartUuid,
    cartExp: state.cartExp,
    cart: state.cart,
    seguroDeCancelacion: state.seguroDeCancelacion,
    idiomaSelected: state.idiomaSelected,
  })
})


export default new Vuex.Store({
  state: {
    plazasDisponibles: 0,
    cart: [],
    cartExp: null,
    cartUuid: null,
    timerId: null,
    timerCounter: null,
    cartAnimation: false,

    comunidadesAutonomas: [
      { value: null, text:'Seleccione una Comunidad Autónoma', selected: false},
      {value:"Andalucía" , text:"Andalucía", selected: false},
      {value:"Aragón" , text:"Aragón", selected: false },
      {value:"Asturias, Principado de" , text: "Principado de Asturias", selected: false },
      {value:"Balears, Illes" , text:"Islas Baleares", selected: false },
      {value:"Canarias" , text:"Canarias", selected: false },
      {value:"Cantabria" , text:"Cantabria", selected: false },
      {value:"Castilla - La Mancha" , text: "Castilla - La Mancha", selected: false },
      {value:"Castilla y León" , text:"Castilla y León", selected: false },
      {value: "Catalunya", text:"Catalunya", selected: false },
      {value: "Ceuta", text:"Ceuta", selected: false },
      {value: "Comunitat Valenciana", text:"Comunidad Valenciana", selected: false },
      {value: "Extremadura", text:"Extremadura", selected: false },
      {value: "Galicia", text:"Galicia", selected: false },
      {value: "Madrid, Comunidad de", text:"Madrid", selected: false },
      {value: "Ciudad de Melilla", text:"Ciudad de Melilla", selected: false },
      {value: "Murcia, Región de" , text:"Región de Murcia", selected: false },
      {value: "Navarra, Comunidad Foral de", text:"Comunidad Foral de Navarra", selected: false },
      {value: "País Vasco", text:"País Vasco", selected: false },
      {value: "Rioja, La", text:"La Rioja", selected: false }
    ],

    provincias:[
      { value: null, text:'Seleccione provincia *', selected:true},
      {value:"01" , text:"Álava" , selected: false, comunidad:'País Vasco' },
      {value:"02" , text:"Albacete" , selected: false, comunidad:'Castilla - La Mancha' },
      {value:"03" , text: "Alicante" , selected: false, comunidad:'Comunitat Valenciana' },
      {value:"04" , text:"Almería" , selected: false, comunidad:'Andalucía' },
      {value:"33" , text:"Asturias" , selected: false, comunidad:'Asturias, Principado de' },
      {value:"05" , text: "Avila" , selected: false, comunidad:'Castilla y León' },
      {value:"06" , text:"Badajoz" , selected: false, comunidad:'Extremadura' },
      {value: "08", text:"Barcelona" , selected: false, comunidad:'Cataluña' },
      {value: "48", text:"Vizcaya" , selected: false, comunidad:'País Vasco' },
      {value: "09", text:"Burgos" , selected: false, comunidad:'Castilla y León' },
      {value: "10", text:"Cáseres" , selected: false, comunidad:'Extremadura' },
      {value: "11", text:"Cádiz" , selected: false, comunidad:'Andalucía' },
      {value: "39", text:"Cantabria" , selected: false, comunidad:'Cantabria' },
      {value: "12", text:"Castellón" , selected: false, comunidad:'Comunitat Valenciana' },
      {value: "51", text:"Ceuta" , selected: false, comunidad:'Ceuta'},
      {value: "13" , text:"Ciudad Real" , selected: false, comunidad:'Castilla - La Mancha' },
      {value: "14", text:"Córdoba" , selected: false, comunidad:'Andalucía' },
      {value: "16", text:"Cuenca" , selected: false, comunidad:'Castilla - La Mancha' },
      {value: "20", text:"Gipuzcoa" , selected: false, comunidad:'País Vasco'},
      {value: "17", text:"Girona" , selected: false, comunidad:'Castilla y León'},
      {value: "18", text:"Granada" , selected: false, comunidad:'Andalucía' },
      {value: "19", text:"Guadalajara" , selected: false, comunidad:'Castilla - La Mancha' },
      {value: "21", text:"Huelva" , selected: false, comunidad:'Andalucía' },
      {value: "22", text:"Huesca" , selected: false, comunidad:'Aragón' },
      {value: "07", text:"Islas Baleares" , selected: false, comunidad:"Balears, Illes" },
      {value: "23", text:"Jaén" , selected: false, comunidad:'Andalucía' },
      {value: '15', text:"La Coruña", selected: false, comunidad:'Galicia' },
      {value: "26", text:"La Rioja" , selected: false, comunidad:'Rioja, La' },
      {value: "35", text:"Las Palmas" , selected: false, comunidad:'Canarias' },
      {value: "24", text:"León" , selected: false, comunidad:'Castilla y León' },
      {value: "25", text:"Lleida" , selected: false, comunidad:'Cataluña' },
      {value: "27", text:"Lugo" , selected: false, comunidad:'Galicia' },
      {value: "28", text:"Madrid" , selected: false, comunidad:'Madrid, Comunidad de' },
      {value: "29", text:"Málaga" , selected: false, comunidad:'Andalucía' },
      {value: "52", text:"Melilla" , selected: false, comunidad:'Ciudad de Melilla' },
      {value: "30", text:"Murcia" , selected: false, comunidad:'Murcia, Región de' },
      {value: "31", text:"Navarra" , selected: false, comunidad:'Navarra, Comunidad Foral de' },
      {value: "32", text:"Ouresense" , selected: false, comunidad:'Galicia' },
      {value: "34", text:"Palencia" , selected: false, comunidad:'Castilla y León' },
      {value: "36", text:"Pontevedra" , selected: false, comunidad:'Galicia' },
      {value: "37", text:"Salamanca" , selected: false, comunidad:'Castilla y León' },
      {value: "38", text:"Santa Cruz, Tenerife" , selected: false, comunidad:'Canarias' },
      {value: "40", text:"Segovia" , selected: false, comunidad:'Castilla y León' },
      {value: "41", text:"Sevilla" , selected: false, comunidad:'Andalucía' },
      {value: "42", text:"Soria" , selected: false, comunidad:'Castilla y León' },
      {value: "43", text:"Tarragona" , selected: false, comunidad:'Cataluña' },
      {value: "44", text:"Teruel" , selected: false, comunidad:'Aragón' },
      {value: "45", text:"Toledo" , selected: false, comunidad:'Castilla - La Mancha' },
      {value: "46", text:"Valencia" , selected: false, comunidad:'Comunitat Valenciana' },
      {value: "47", text:"Valladolid" , selected: false, comunidad:'Castilla y León' },
      {value: "49", text:"Zamora" , selected: false, comunidad:'Castilla y León' },
      {value: "50", text:"Zaragoza" , selected: false, comunidad:'Aragón' },
    ],

    holidays: [
      '2023-02-28', '2023-05-18', '2023-09-27', '2024-02-28', '2024-05-18', '2024-09-27'
    ],
    monumentoId: null,
    totalBoletos: 0,
    idiomaSelected: null,
  },
  getters: {
    holidays: state => {
      return state.holidays;
    },
    cartStatus: state => {
      let statusObj = {uuid: state.cartUuid, exp: state.cartExp};
      return statusObj;
    },
    getExp: state => {
      return state.cartExp;
    },
    total: state=> {
      if(state.cart.length > 0){
        return state.cart.map( item => item.itemTotal ).reduce((total, amount) => total + amount);
      }
      else{
        return 0;
      }
    },
    itemsInCart: state => {
      return state.cart;
    },
    numberOfTickets: state => {
      return state.cart.length;
    },
    cartAnimation: state => {
      return state.cartAnimation;
    },

    comunidadesAutonomas: state => {
      return state.comunidadesAutonomas;
    },
    provincias: state => {
      return state.provincias;
    },
    getMonumento: state => {
      return state.monumentoId;
    },

    espacioParaBoletos(state){
      if(state.plazasDisponibles == null){
        return null;
      }
      else if( state.plazasDisponibles ){
        return state.plazasDisponibles;
      }
      else{
        return 0;
      }
    },

    boletosAgregados(state){
      return state.totalBoletos;
    },

    getIdioma: state =>{
      return state.idiomaSelected;
    },

  },
  mutations: {
    addToCart(state, payload){
      // state.cartAnimation = true;
      state.cartAnimation = true;
      return state.cart.push(payload);
    },

    removeFromCart(state, payload){
      let itemIndex = state.cart.findIndex( (item)=> item.itemUuid == payload )

      if(itemIndex != -1){
        if(state.cart.length == 1){
          state.cartUuid = null;
          state.cartExp = null;
          state.timeCounter = null;
          state.seguroDeCancelacion = false;
        }
        state.cartAnimation = false;

        return state.cart.splice( itemIndex, 1 );
      }
      else{
        return state.cart;
      }
    },

    removeAllItems(state){
      state.seguroDeCancelacion = false;
      state.cartUuid = null;
      state.cartExp = null;
      return state.cart = [];
    },

    setMonumentoId(state, monumento){
      state.monumentoId = monumento;
      return state.monumentoId
    },

    resetBoletos(state){
      state.totalBoletos = 0;
      return state.totalBoletos;
    },

    quitarBoleto(state, payload){
      state.totalBoletos -= payload;
      return state.totalBoletos;
    },

    agregarBoleto(state, payload){
      state.totalBoletos += payload;
      return state.totalBoletos;
    },

    guardarPlazas( state, payload ){
      state.plazasDisponibles = payload;
      return state.plazasDisponibles;
    },

    addReservationId( state, payload ){
      const { itemUuid, reservationId } = payload;
      state.cart.forEach( c => {
        if( c.itemUuid == itemUuid){
          c.reservationId = reservationId;
        }
      })

      return state.cart;
    },
    setIdioma( state, idioma ){
      state.idiomaSelected = idioma;
    }

  },
  actions: {
    async addToCart( { commit, state }, products ){
      if( state.cartUuid ){
        let preReservas = { 
          "uuid":products.itemUuid, 
          "pax":products.itemTickets.map( item => item.cantidad ).reduce( (acc, curr) => acc + curr, 0 ), 
          "eventoId": products.itemID, 
          "asientos": products.asientos,
          "eventoClorianId":products.itemClorianId };

        let result = await ApiService.updateCarrito( state.cartUuid, preReservas )        
        state.cartExp = result.data.cartExp;
      }

      else{
        const payload = {
          uuid: uuidv4(),
          preReservas: [ { 
            "uuid":products.itemUuid, 
            "pax":products.itemTickets.map( item => item.cantidad ).reduce( (acc, curr) => acc + curr, 0 ), 
            "eventoId": products.itemID, 
            "asientos": products.asientos,
            "eventoClorianId":products.itemClorianId 
          } ],
          status: false
        }

        let result = await ApiService.createCarrito( payload );
        state.cartUuid = result.data.uuid;
        state.cartExp = result.data.expireAt;
      }

      products.carritoUuid = state.cartUuid;
      commit( 'addToCart', products );

    },

    /** Removemos la reserva del carrito */
    async reservaDelete( { commit, state }, reserva ){
      commit( 'removeFromCart', reserva )
      await ApiService.removeFromCarrito( state.cartUuid, reserva )
      return true;
    },

    /**
     * Disparamos el temporizador para eliminar las reservas del carrito al cumplirse los 15 minutos
     * @param {*} param0 
     */
    async triggerTimer( {commit, state} ){
      if( !state.timerId ){

        state.timerId = setInterval( () => { 
            let now = moment();

            let diffTime = moment.utc( state.cartExp?? 0 ) - now;

            let duration = moment.duration( diffTime - 1000, 'milliseconds' )

            state.timeCounter = (duration.minutes() + ":" + duration.seconds() );

            console.log(state.timeCounter);
            if( duration <= 0 ){
                state.timeCounter = null;
                clearInterval( state.timerId );
                state.timerId = null;
                commit('removeAllItems')
            }

            return state.timeCounter
        }, 1000 )
      }
    },

    async removeAllCartItems( { commit, state} ){
      try{
        await ApiService.deleteCart( state.cartUuid )
        commit('removeAllItems')
        return true;
      }
      catch(err){
        console.log(err)
      }
    }


  },
  modules: {

  },
  plugins: [vuexLocal.plugin]
})
