<template>
    <mdb-container>
        <mdb-modal :show="mostrar" size="lg" @close=" () => $emit('close')">
            <mdb-modal-header class="unique-color-dark text-white">
                <mdb-modal-title class="title text-white">
                    {{ titulo }}
                </mdb-modal-title>
            </mdb-modal-header>

            <mdb-modal-body>
                <div class="text-start">
                    <div class="text-start px-5">
                        <mdb-row class="">
                            <p class="section-title text-uppercase">{{ $t('default.PRODUCTS_DISCOUNTS') }}</p>
                        </mdb-row>
                        <span class="d-flex flex-column align-items-start text-start small-text" v-html="htmlText"></span>
                    </div>
                </div>
            </mdb-modal-body>

            <mdb-modal-footer>

                <mdb-btn color="indigo darken-4" size="sm" @click="() => $emit('close')">
                    <div class="d-flex">
                        <p class="my-auto font-weight-bold">{{ $t('default.PRODUCTS_CLOSE') }}</p>
                    </div>
                </mdb-btn>

                <mdb-btn color="primary" size="sm" @click="() => $emit('comprar', { productId: productoId })">
                    <div class="d-flex">
                        <span class="my-auto mr-2">
                            <font-awesome-icon icon="fa-cart-shopping" size="sm"/>
                        </span>
                        <p class="my-auto font-weight-bold">{{ $t('default.PRODUCTS_RESERVE') }}</p>
                    </div>
                </mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </mdb-container>
</template>

<script>
export default{
    name:"DescuentosComponent",
    props:{
        mostrar: Boolean,
        htmlText: String,
        titulo: String,
        productoId: String
    },
    data(){
        return{

        }
    },
    methods:{

    },
    computed:{
        text(){
            return this.htmlText;
        }
    }
}
</script>

<style scoped>
    #horarios ul{
        list-style-type:none;
        padding-left: 0pt;
    }
    .title{
        font-size: 30px;
        font-weight: 700;
    }
    .section-title{
        font-size: 25px;
        font-weight: 600;
    }
    .small-text{
        font-size: 12px;
        text-align: left !important;
    }
</style>
