import http from "../http-common";

class ApiDataService{
    getVisitas(idioma, id, tipo){
        return http.get("/entradasByMonumento", { params:{'monumento':id, 'idioma':idioma, 'tipo':tipo} });
    }

    getWelcome( idioma, monumento ){
        return http.get("/welcomeByMonument", { params:{'monumento':monumento, 'idioma':idioma } });
    }
    consultarReserva( data, monumento ){
        return http.get('/consultarReserva', {
            params:{
                referencia: data.referencia,
                email: data.email,
                monumento: monumento
            }
        })
    }
    enviarReserva( localizador ){
        return http.post(`/enviar-reserva-correo/${localizador}`);
    }
    /**
     * 
     * @param { Number } id - El id del producto que se quiere comprar
     * @param { String } idioma - El idioma del usuario de la web
     * @returns - La información General de este producto
     */
    getVisitaInfo( id, idioma ){
        return http.get(`/entradaInfoByIdProduct`, { params:{'idProduct':id, 'idioma': idioma } } )
    }

    /**
     * 
     * @param { String } idioma - El idioma del usuario de la web
     * @param { String } id - El id del tipo mongodb que se asigna al producto de que se quieren saber las fechas
     * @param { Date } from - La fecha a partir de la que se quiere consultar
     * @param { Date } to - La fecha a final de la que se quiere consultar
     * @returns 
     */
    consultarDisponibilidadDeFechas(idioma, id, from, to ){
        return http.get("/calendarioDisponible", { params:{ 'idProduct': id, 'idioma':idioma, 'from':from, 'to': to} });
    }

    consultarHorariosParaFecha( idProducto, fecha ){
        return http.get("/horarios-disponibles", { params:{ 'idProduct': idProducto, 'date': fecha } });
    }
    
    consultarDisponibilidadParaHorario( eventId, places ){
        return http.get('/plazas-disponibles', {params:{ 'eventId': eventId, 'places': places }} )
    }

    createCarrito(carrito){
        return http.post('/carrito', carrito)
    }

    updateCarrito( uuid, reserva ){
        return http.put(`/carrito/${uuid}`, reserva )
    }

    removeFromCarrito( uuid, reservaId ){
        return http.put(`/carrito/delete-reserva/${reservaId}`)
    }

    getCarrito( uuid ){
        return http.get(`/carrito?carrito=${uuid}`)
    }

    initRedsys(payload){
        return http.post('/redsysConnection', payload)
    }

    redsysClientConfirmation(Ds_Signature, Ds_MerchantParameters, Ds_Signature_Version){
        return http.get('/redsys-confirmacion-cliente', {
            params:{
                Ds_Signature:Ds_Signature,
                Ds_Signature_Version: Ds_Signature_Version,
                Ds_MerchantParameters: Ds_MerchantParameters
            }
        })
    }

    getZonasConfig( configuracionId ){
        return http.get(`/fecha/asientos?configuracion=${configuracionId}`)
    }

    verifySeats( seatsId, zona, configuracionId, filas ){
        return http.get('/free-seats', {
            params: {
                seats: seatsId,
                zona: zona,
                configuracionId: configuracionId,
                filas: filas
            }
        })
    }

    getCartInfo( cartUuid ){
        return http.get('/get-cart-info', {
            params: {
                cartUuid: cartUuid
            }
        })
    }

}

export default new ApiDataService();
