var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LayoutDefault" },
    [
      _c(
        "mdb-navbar",
        {
          staticClass: "LayoutDefault__nav white py-0",
          attrs: { position: "top", scrolling: "", dark: "" },
        },
        [
          _c(
            "mdb-navbar-brand",
            { staticClass: "d-flex p-0", attrs: { role: "button" } },
            [
              _c(
                "a",
                { attrs: { href: "https://alcazabaygibralfaro.malaga.eu" } },
                [
                  _c("img", {
                    staticClass: "monument-logo",
                    attrs: {
                      src: require("../assets/logo-malaga.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "mdb-navbar",
        {
          staticClass: "LayoutDefault__nav main-navbar-margin",
          attrs: {
            color: "unique-color",
            position: "top",
            dark: "",
            expand: "large",
          },
        },
        [
          _c(
            "mdb-navbar-toggler",
            { staticClass: "py-0 text-uppercase text-right" },
            [
              _c(
                "mdb-navbar-nav",
                { staticClass: "py-0" },
                [
                  _c(
                    "mdb-nav-item",
                    { staticClass: "my-auto", attrs: { href: "/?home=true" } },
                    [
                      _c("div", { staticClass: "my-auto" }, [
                        _c("p", { staticClass: "my-auto" }, [
                          _vm._v(_vm._s(_vm.$t("default.NAVBAR_MENU_HOME"))),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.activarLink
                    ? _c(
                        "mdb-nav-item",
                        {
                          staticClass: "my-auto",
                          attrs: { href: "/actividades" },
                        },
                        [
                          _c("div", { staticClass: "my-auto" }, [
                            _c("p", { staticClass: "my-auto" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("default.NAVBAR_MENU_ACTIVIDADES")
                                )
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "mdb-nav-item",
                    {
                      staticClass: "my-auto",
                      attrs: { href: "/consultar-entradas" },
                    },
                    [
                      _c("div", { staticClass: "my-auto" }, [
                        _c("p", { staticClass: "my-auto" }, [
                          _vm._v(_vm._s(_vm.$t("default.NAVBAR_MENU_TICKETS"))),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "mdb-nav-item",
                    {
                      staticClass: "my-auto",
                      attrs: { href: "/informacion-importante" },
                    },
                    [
                      _c("div", { staticClass: "my-auto" }, [
                        _c("p", { staticClass: "my-auto" }, [
                          _vm._v(
                            _vm._s(_vm.$t("default.NAVBAR_MENU_INFORMATION"))
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "mdb-nav-item",
                    { staticClass: "my-auto" },
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "animated",
                          class: { "fa-bounce": _vm.nuevoItemAgregado },
                          attrs: { flat: "", size: "sm" },
                          on: { click: _vm.goToCart },
                        },
                        [
                          _c(
                            "font-awesome-layers",
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "fa-cart-shopping", size: "2x" },
                              }),
                              _c("font-awesome-layers-text", {
                                attrs: {
                                  counter: "",
                                  transform: "shrink-3",
                                  value: _vm.ticketsAmount,
                                  position: "top-left",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "mdb-dropdown",
                    { staticClass: "nav-item my-auto", attrs: { tag: "li" } },
                    [
                      _c(
                        "mdb-dropdown-toggle",
                        {
                          staticClass: "ms-auto text-white",
                          attrs: {
                            slot: "toggle",
                            tag: "a",
                            navLink: "",
                            "waves-fixed": "",
                          },
                          slot: "toggle",
                        },
                        [
                          _vm.$i18n.locale === "es" || _vm.$i18n.locale == null
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item d-inline",
                                  attrs: { role: "button" },
                                  on: {
                                    click: () => {
                                      _vm.$i18n.locale = "es"
                                      _vm.$store.commit(
                                        "setIdioma",
                                        this.$i18n.locale
                                      )
                                      _vm.reload()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "30px",
                                      "margin-right": "20px",
                                    },
                                    attrs: {
                                      src: require("../assets/Spain_flag.png"),
                                      alt: "flag",
                                    },
                                  }),
                                  _vm._v("ES"),
                                ]
                              )
                            : _vm._e(),
                          _vm.$i18n.locale === "en"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item d-inline",
                                  attrs: { role: "button" },
                                  on: {
                                    click: () => {
                                      _vm.$i18n.locale = "en"
                                      _vm.$store.commit(
                                        "setIdioma",
                                        this.$i18n.locale
                                      )
                                      _vm.reload()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "30px",
                                      "margin-right": "20px",
                                    },
                                    attrs: {
                                      src: require("../assets/uk_flag.png"),
                                      alt: "flag",
                                    },
                                  }),
                                  _vm._v("EN"),
                                ]
                              )
                            : _vm._e(),
                          _vm.$i18n.locale === "fr"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item d-inline",
                                  attrs: { role: "button" },
                                  on: {
                                    click: () => {
                                      _vm.$i18n.locale = "fr"
                                      _vm.$store.commit(
                                        "setIdioma",
                                        this.$i18n.locale
                                      )
                                      _vm.reload()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "30px",
                                      "margin-right": "20px",
                                    },
                                    attrs: {
                                      src: require("../assets/france_flags.png"),
                                      alt: "flag",
                                    },
                                  }),
                                  _vm._v("FR"),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "mdb-dropdown-menu",
                        { staticClass: "ms-auto" },
                        [
                          _c("mdb-dropdown-item", { staticClass: "bg-white" }, [
                            _c(
                              "a",
                              {
                                attrs: { role: "button" },
                                on: {
                                  click: () => {
                                    _vm.$i18n.locale = "es"
                                    _vm.$store.commit(
                                      "setIdioma",
                                      this.$i18n.locale
                                    )
                                    _vm.reload()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: require("../assets/Spain_flag.png"),
                                    alt: "flag",
                                  },
                                }),
                                _vm._v("ES"),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", { staticClass: "bg-white" }, [
                            _c(
                              "a",
                              {
                                attrs: { role: "button" },
                                on: {
                                  click: () => {
                                    _vm.$i18n.locale = "en"
                                    _vm.$store.commit(
                                      "setIdioma",
                                      this.$i18n.locale
                                    )
                                    _vm.reload()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: require("../assets/uk_flag.png"),
                                    alt: "flag",
                                  },
                                }),
                                _vm._v("EN"),
                              ]
                            ),
                          ]),
                          _c("mdb-dropdown-item", { staticClass: "bg-white" }, [
                            _c(
                              "a",
                              {
                                attrs: { role: "button" },
                                on: {
                                  click: () => {
                                    _vm.$i18n.locale = "fr"
                                    _vm.$store.commit(
                                      "setIdioma",
                                      this.$i18n.locale
                                    )
                                    _vm.reload()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: require("../assets/france_flags.png"),
                                    alt: "flag",
                                  },
                                }),
                                _vm._v("FR"),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "main",
        { staticClass: "LayoutDefault__main" },
        [
          _vm._t("default"),
          _c(
            "div",
            {
              staticClass: "fixed-button justify-content-start big-text mr-4",
              class: {
                "d-none": _vm.ticketsAmount == 0,
                "d-lg-flex": _vm.mostrarTiempo,
              },
              attrs: { role: "button" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "p-2 bg-white rounded opacity-75 ml-3 mb-3 shadow-lg d-flex",
                  on: {
                    click: () => {
                      _vm.$router.push("/checkout")
                    },
                  },
                },
                [
                  _c(
                    "p",
                    { staticClass: "ml-2 mr-2 my-auto font-weight-bold" },
                    [_vm._v(_vm._s(_vm.timeCounter))]
                  ),
                  _c("font-awesome-icon", {
                    staticClass: "text-align-start",
                    attrs: {
                      size: "3x",
                      icon: "fa-clock mr-auto ml-3 my-auto",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
      _c(
        "footer",
        {
          staticClass:
            "LayoutDefault__footer text-white unique-color mt-auto pb-0",
        },
        [
          _c(
            "div",
            { staticClass: "container py-4" },
            [
              _c("div", { staticClass: "d-flex ml-0 mb-2 pl-0" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-white ml-3",
                    attrs: { href: "/Politica_Privacidad", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.FOOTER_PRIVACY")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-white ml-3",
                    attrs: { href: "/Aviso_Legal", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.FOOTER_LEGAL")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-white ml-3",
                    attrs: { href: "/condiciones-generales", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.FOOTER_GENERAL")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-white ml-3",
                    attrs: {
                      href: "https://alcazabaygibralfaro.malaga.eu/es/visitas/horarios-y-tarifas/",
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.FOOTER_SCHEDULE_AND_RATES")))]
                ),
              ]),
              _c(
                "mdb-row",
                [
                  _c(
                    "mdb-col",
                    [
                      _c(
                        "mdb-row",
                        { staticClass: "text-left" },
                        [
                          _c(
                            "mdb-col",
                            { staticClass: "py-2", attrs: { col: "12" } },
                            [
                              _c("p", [_vm._v("C/ Alcazabilla, 2")]),
                              _c("p", [_vm._v("29012 - Málaga")]),
                              _c("p", [_vm._v("gmpalcazaba@malaga.eu")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("mdb-col", { staticClass: "my-auto" }, [
                    _c("img", {
                      staticStyle: { height: "100px" },
                      attrs: { src: require("@/assets/aytoMalg.png") },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "clearfix unique-color-dark py-2 align-middle" },
            [
              _c("span", { staticClass: "my-auto" }, [
                _vm._v("© Spainheritagenetwork"),
              ]),
              _c("div", { staticClass: "float-right pr-5 pb-0 mb-0" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.facebook.com/alcazabaygibralfaromalaga/",
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-white mx-2 fa-2x",
                      attrs: { icon: "fa-brands fa-facebook-square" },
                    }),
                  ],
                  1
                ),
                _c(
                  "a",
                  { attrs: { href: "https://twitter.com/alcazabamlg" } },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-white mx-2 fa-2x",
                      attrs: { icon: "fa-brands fa-twitter-square" },
                    }),
                  ],
                  1
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.instagram.com/alcazabaygibralfaromalaga/",
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-white mx-2 fa-2x",
                      attrs: { icon: "fa-brands fa-instagram-square" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }