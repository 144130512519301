<template>
    <div class="mx-2 mb-5 animated slideInUp">
        <mdb-card class="booking-card align-bottom" style="width: 22rem; height: 500px;">
            <mdb-view hover cascade>
                <a href="#">
                    <mdb-card-image :src="background" style="max-height:250px"></mdb-card-image>
                </a>
            </mdb-view>
            <mdb-card-body class="align-bottom">
                <mdb-card-title class="font-weight-bold">{{ nombreVisita }}</mdb-card-title>
                <hr />
                <mdb-card-text>
                    <div class="clearfix">
                        <!-- <mdb-btn class="red darken-3 float-left px-2" size="sm" v-bind:class="{'d-none': documento == undefined}"
                            tag="a" target="_blank"
                            :href="documento">
                            <div class="">
                                <p class="my-auto font-weight-bold">{{ $t('default.PDF_SCHEDULE') }}</p>
                            </div>
                        </mdb-btn> -->

                        <div role="button" class="float-left" v-bind:class="{'d-none': documento == null}">
                            <a :href="documento" target="_blank"><small class="text-danger" role="button" tabindex="0"><u role="button" @click="consultaPrecios">{{ $t('default.PROGRAMA') }}</u></small></a>
                        </div>

                        <div role="button" class="float-right">
                            <a><small class="text-danger" role="button" tabindex="0"><u role="button" @click="consultaPrecios">{{ $t('default.PRODUCTS_CARD_PRICING_LIST') }}</u></small></a>
                        </div>
                        <br/>
                        <p class="float-right">{{ $t('default.PRODUCTS_ONLINE_PRICE') }}:<span class="font-weight-bold">{{ precioString }}</span></p>
                    </div>
                </mdb-card-text>

                <mdb-row>
                    <mdb-col>
                        <mdb-btn color="dark darken-4" class="float-left" size="sm" @click="() => $emit('info', {'titulo':visita, 'horarios':horarios, 'descripcion':descripcion, 'productoId': productoId } )">
                            <div class="d-flex">
                                <span class="my-auto mr-2">
                                    <font-awesome-icon icon="fa-plus" size="sm"/>
                                </span>
                                <p class="my-auto font-weight-bold">{{ $t('default.PRODUCTS_INFORMATION') }}</p>
                            </div>
                        </mdb-btn>
                    </mdb-col>
                    <mdb-col>
                        <mdb-btn color="primary" class="float-right" size="sm" @click="() => $emit('comprar', { 'productoId': productoId, 'visita': visita})">
                            <div class="d-flex">
                                <span class="my-auto mr-2">
                                    <font-awesome-icon icon="fa-cart-shopping" size="sm"/>
                                </span>
                                <p class="my-auto font-weight-bold">{{ $t('default.PRODUCTS_RESERVE') }}</p>
                            </div>
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>

            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
export default {
    name:'ProductosCard',
    props:{
        banner: String,
        descripcion: String,
        visita: String,
        horarios: String,
        precio: { type: String, default: " "},
        productoId: Number,
        descuentos: String,
        documento: {String, required: false }
    },
    data(){
        return{

        }
    },
    methods:{
        consultaPrecios(){
            this.$emit('descuentos', {'titulo':this.visita, 'productoId': this.productoId, descuentos: this.descuentos } )
        }
    },
    computed:{
        background(){
            return this.banner;
        },
        nombreVisita(){
            return this.visita
        },
        horariosTexto(){
            return this.horarios
        },
        precioString(){
            return parseFloat(this.precio).toLocaleString( 'es-ES', { style:'currency', currency:'EUR'} );
        }
    }
}
</script>

<style scoped>
    .card{
        align-items: center;
        /* min-width: 120px; */
    }
</style>