var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-2 mb-5 animated slideInUp" },
    [
      _c(
        "mdb-card",
        {
          staticClass: "booking-card align-bottom",
          staticStyle: { width: "22rem", height: "500px" },
        },
        [
          _c("mdb-view", { attrs: { hover: "", cascade: "" } }, [
            _c(
              "a",
              { attrs: { href: "#" } },
              [
                _c("mdb-card-image", {
                  staticStyle: { "max-height": "250px" },
                  attrs: { src: _vm.background },
                }),
              ],
              1
            ),
          ]),
          _c(
            "mdb-card-body",
            { staticClass: "align-bottom" },
            [
              _c("mdb-card-title", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.nombreVisita)),
              ]),
              _c("hr"),
              _c("mdb-card-text", [
                _c("div", { staticClass: "clearfix" }, [
                  _c(
                    "div",
                    {
                      staticClass: "float-left",
                      class: { "d-none": _vm.documento == null },
                      attrs: { role: "button" },
                    },
                    [
                      _c(
                        "a",
                        { attrs: { href: _vm.documento, target: "_blank" } },
                        [
                          _c(
                            "small",
                            {
                              staticClass: "text-danger",
                              attrs: { role: "button", tabindex: "0" },
                            },
                            [
                              _c(
                                "u",
                                {
                                  attrs: { role: "button" },
                                  on: { click: _vm.consultaPrecios },
                                },
                                [_vm._v(_vm._s(_vm.$t("default.PROGRAMA")))]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "float-right", attrs: { role: "button" } },
                    [
                      _c("a", [
                        _c(
                          "small",
                          {
                            staticClass: "text-danger",
                            attrs: { role: "button", tabindex: "0" },
                          },
                          [
                            _c(
                              "u",
                              {
                                attrs: { role: "button" },
                                on: { click: _vm.consultaPrecios },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.PRODUCTS_CARD_PRICING_LIST")
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("br"),
                  _c("p", { staticClass: "float-right" }, [
                    _vm._v(
                      _vm._s(_vm.$t("default.PRODUCTS_ONLINE_PRICE")) + ":"
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.precioString)),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "mdb-row",
                [
                  _c(
                    "mdb-col",
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "float-left",
                          attrs: { color: "dark darken-4", size: "sm" },
                          on: {
                            click: () =>
                              _vm.$emit("info", {
                                titulo: _vm.visita,
                                horarios: _vm.horarios,
                                descripcion: _vm.descripcion,
                                productoId: _vm.productoId,
                              }),
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "span",
                              { staticClass: "my-auto mr-2" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "fa-plus", size: "sm" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "my-auto font-weight-bold" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("default.PRODUCTS_INFORMATION"))
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "mdb-col",
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "primary", size: "sm" },
                          on: {
                            click: () =>
                              _vm.$emit("comprar", {
                                productoId: _vm.productoId,
                                visita: _vm.visita,
                              }),
                          },
                        },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "span",
                              { staticClass: "my-auto mr-2" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: "fa-cart-shopping",
                                    size: "sm",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "my-auto font-weight-bold" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("default.PRODUCTS_RESERVE"))
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }