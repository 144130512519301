var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    [
      _c(
        "mdb-modal",
        {
          attrs: { show: _vm.mostrar, size: "lg" },
          on: { close: () => _vm.$emit("close") },
        },
        [
          _c(
            "mdb-modal-header",
            { staticClass: "unique-color-dark text-white" },
            [
              _c("mdb-modal-title", { staticClass: "title text-white" }, [
                _vm._v(" " + _vm._s(_vm.titulo) + " "),
              ]),
            ],
            1
          ),
          _c("mdb-modal-body", [
            _c("div", { staticClass: "text-start" }, [
              _c(
                "div",
                { staticClass: "text-start px-5" },
                [
                  _c("mdb-row", {}, [
                    _c("p", { staticClass: "section-title text-uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("default.PRODUCTS_DISCOUNTS"))),
                    ]),
                  ]),
                  _c("span", {
                    staticClass:
                      "d-flex flex-column align-items-start text-start small-text",
                    domProps: { innerHTML: _vm._s(_vm.htmlText) },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "mdb-modal-footer",
            [
              _c(
                "mdb-btn",
                {
                  attrs: { color: "indigo darken-4", size: "sm" },
                  on: { click: () => _vm.$emit("close") },
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("p", { staticClass: "my-auto font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("default.PRODUCTS_CLOSE"))),
                    ]),
                  ]),
                ]
              ),
              _c(
                "mdb-btn",
                {
                  attrs: { color: "primary", size: "sm" },
                  on: {
                    click: () =>
                      _vm.$emit("comprar", { productId: _vm.productoId }),
                  },
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "span",
                      { staticClass: "my-auto mr-2" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "fa-cart-shopping", size: "sm" },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "my-auto font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("default.PRODUCTS_RESERVE"))),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }