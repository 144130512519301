var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "margin-malaga-container" },
    [
      _c(
        "carousel",
        {
          staticClass: "margin-malaga-container",
          attrs: {
            autoplay: true,
            perPage: 1,
            loop: true,
            autoplayTimeout: 3000,
            paginationEnabled: false,
          },
        },
        [
          _c("slide", [
            _c("div", [
              _c("img", {
                staticClass: "d-block mx-auto",
                staticStyle: {
                  "max-height": "350px",
                  width: "auto",
                  "max-width": "100vw !important",
                },
                attrs: { src: require("@/assets/1.jpg") },
              }),
            ]),
          ]),
          _c("slide", [
            _c("div", [
              _c("img", {
                staticClass: "d-block mx-auto",
                staticStyle: {
                  "max-height": "350px",
                  width: "auto",
                  "max-width": "100vw !important",
                },
                attrs: { src: require("@/assets/2.jpg") },
              }),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "mdb-container",
        [
          _c("mdb-jumbotron", [
            _c("h5", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.$t("default.JUMBOTRON_MESSAGE"))),
            ]),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "mt-5 d-flex justify-content-center" },
        [
          _c(
            "mdb-card-group",
            { staticClass: "justify-content-center" },
            _vm._l(_vm.productos, function (producto, index) {
              return _c("productos-card", {
                key: index,
                attrs: {
                  visita: producto.nombres[_vm.idiomaIndex].texto,
                  horarios: producto.horarios[_vm.idiomaIndex].texto,
                  precio: producto.precioOrientativo,
                  descripcion: producto.descripciones[_vm.idiomaIndex].texto,
                  banner: `${_vm.imagenesUrl}/visitas_castillos/${producto.oldId}/${producto.banner}`,
                  productoId: producto.oldId,
                  descuentos: producto.descuentos[_vm.idiomaIndex].texto,
                  documento: producto.documentos,
                },
                on: {
                  info: _vm.abrirDetalles,
                  comprar: _vm.goToPurchase,
                  descuentos: _vm.abrirDescuentos,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("descuentos-component", {
        attrs: {
          mostrar: _vm.mostrarDescuentos,
          titulo: _vm.detallesInfo.visita,
          htmlText: _vm.detallesInfo.descuentos,
          productoId: _vm.detallesInfo.productoId,
        },
        on: {
          comprar: (evt) =>
            _vm.$router.push(`/reservarEntradas?idProducto=${evt.productId}`),
          close: () => (_vm.mostrarDescuentos = false),
        },
      }),
      _c("details-component", {
        attrs: {
          detalles: _vm.mostrarDetalles,
          titulo: _vm.detallesInfo.visita,
          horarios: _vm.detallesInfo.horarios,
          htmlText: _vm.detallesInfo.detalles,
          productoId: _vm.detallesInfo.productoId,
        },
        on: {
          comprar: (evt) =>
            _vm.$router.push(`/reservarEntradas?idProducto=${evt.productId}`),
          close: () => (_vm.mostrarDetalles = false),
        },
      }),
      _c(
        "mdb-modal",
        {
          attrs: { show: _vm.popupStart, centered: "" },
          on: { close: () => (_vm.popupStart = false) },
        },
        [
          _c("mdb-modal-body", { staticClass: "unique-color" }, [
            _c(
              "div",
              { staticClass: "py-5 px-3" },
              [
                _c(
                  "h3",
                  { staticClass: "font-weight-bold text-uppercase text-light" },
                  [_vm._v(_vm._s(_vm.welcomeTitle))]
                ),
                _c("hr", { staticClass: "red unique-color-dark" }),
                _c(
                  "h5",
                  { staticClass: "text-light ont-weight-bold text-uppercase" },
                  [_vm._v(_vm._s(_vm.welcomeText))]
                ),
                _c(
                  "mdb-btn",
                  {
                    staticClass: "mt-5",
                    attrs: { color: "primary", size: "lg" },
                    on: { click: () => (_vm.popupStart = false) },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.BUTTONS_ACCEPT")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }