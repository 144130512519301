<template>
    <div class="LayoutDefault">
        

        <!-- <mdb-navbar class="LayoutDefault__nav white d-lg-none" position="top" color="unique" dark expand="large">

            <mdb-navbar-brand class="d-flex">
                <div class="d-flex">
                    <font-awesome-icon icon="fa-phone" transform="shrink-3" class="my-auto"></font-awesome-icon>
                    <div class="ml-2">
                        <small slot="tip" style="font-size: x-small;">{{ $t('default.NAVBAR_MENU_SELLS_PHONE') }}</small>
                        <small class="" style="font-size: x-small;">: +34 952 07 62 62</small>
                    </div>
                </div>

            </mdb-navbar-brand>

            <mdb-navbar-toggler class="py-0 text-uppercase text-right">
                <mdb-navbar-nav class="py-0 d-lg-none d-md-inline-block">
                    <mdb-nav-item href="/?home=true" class="my-auto">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_HOME') }}</p>
                        </div>
                    </mdb-nav-item>

                    <mdb-nav-item href="/?home=true" class="my-auto">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_MONUMENTS') }}</p>
                        </div>
                    </mdb-nav-item>

                    <mdb-nav-item href="/consultar-entradas" class="my-auto">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_TICKETS') }}</p>
                        </div>
                    </mdb-nav-item>

                    <mdb-nav-item :href="'/informacion-importante'" class="my-auto">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_INFORMATION') }}</p>
                        </div>
                    </mdb-nav-item>

                    <mdb-nav-item class="my-auto">
                        <mdb-btn flat class="animated" v-bind:class="{'fa-bounce':nuevoItemAgregado}" @click="goToCart" size="sm">
                            <font-awesome-layers>
                                <font-awesome-icon icon="fa-cart-shopping" size="2x"/>
                                <font-awesome-layers-text counter transform="shrink-3" :value="ticketsAmount" position="top-left"/>
                            </font-awesome-layers>
                        </mdb-btn>
                    </mdb-nav-item>

                    <mdb-dropdown tag="li" class="nav-item my-auto">
                        <mdb-dropdown-toggle class="ms-auto text-white" tag="a"  navLink slot="toggle" waves-fixed>
                            <a v-if="$i18n.locale === 'es' || $i18n.locale == null" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='es'; $store.commit('setIdioma', this.$i18n.locale); reload() }"><img src="../assets/Spain_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>ES</a>
                            <a v-if="$i18n.locale === 'en'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='en';$store.commit('setIdioma', this.$i18n.locale); reload()}"><img src="../assets/uk_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>EN</a>
                            <a v-if="$i18n.locale === 'fr'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='fr'; $store.commit('setIdioma', this.$i18n.locale); reload()}"><img src="../assets/france_flags.png" style="width: 30px;margin-right:20px;" alt="flag"/>FR</a>
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="ms-auto">
                            <mdb-dropdown-item class="bg-white"><a role="button" @click="() => { $i18n.locale='es'; $store.commit('setIdioma', this.$i18n.locale);  reload()}"><img src="../assets/Spain_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>ES</a></mdb-dropdown-item>
                            <mdb-dropdown-item class="bg-white"><a role="button" @click="() => { $i18n.locale='en';$store.commit('setIdioma', this.$i18n.locale); reload() }"><img src="../assets/uk_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>EN</a></mdb-dropdown-item>
                            <mdb-dropdown-item class="bg-white"><a role="button" @click="() => { $i18n.locale='fr'; $store.commit('setIdioma', this.$i18n.locale);  reload()}"><img src="../assets/france_flags.png" style="width: 30px;margin-right:20px;" alt="flag"/>FR</a></mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>

                </mdb-navbar-nav>
            </mdb-navbar-toggler>


        </mdb-navbar>
 -->
        <mdb-navbar class="LayoutDefault__nav white py-0" position="top" scrolling dark>

            <mdb-navbar-brand class="d-flex p-0" role="button">
                <a href="https://alcazabaygibralfaro.malaga.eu">
                    <img src="../assets/logo-malaga.png" class="monument-logo" alt="" />
                </a>
            </mdb-navbar-brand>

            <!-- <mdb-navbar-brand :href="spainhnUrl" class="d-md-inline-block p-0 ml-auto" tag="a" target="_blank" role="button">
                <img src="../assets/BOTONREDONDO2.png" class="spainhn-logo" alt="" />
                <p class="p-0 my-auto d-none d-sm-block" style="font-size:x-small; color: rgb(0,0,0) !important">CONOCE LA RED</p>
            </mdb-navbar-brand> -->

        </mdb-navbar>

        <mdb-navbar class="LayoutDefault__nav main-navbar-margin" color="unique-color" position="top" dark expand="large">

            <mdb-navbar-toggler class="py-0 text-uppercase text-right">
                <mdb-navbar-nav class="py-0">

                    <mdb-nav-item href="/?home=true" class="my-auto">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_HOME') }}</p>
                        </div>
                    </mdb-nav-item>
                    <mdb-nav-item href="/actividades" class="my-auto" v-if="activarLink">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_ACTIVIDADES') }}</p>
                        </div>
                    </mdb-nav-item>


                    <mdb-nav-item href="/consultar-entradas" class="my-auto">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_TICKETS') }}</p>
                        </div>
                    </mdb-nav-item>

                    <mdb-nav-item :href="'/informacion-importante'" class="my-auto">
                        <div class="my-auto">
                            <p class="my-auto">{{ $t('default.NAVBAR_MENU_INFORMATION') }}</p>
                        </div>
                    </mdb-nav-item>

                    <mdb-nav-item class="my-auto">
                        <mdb-btn flat class="animated" v-bind:class="{'fa-bounce':nuevoItemAgregado}" @click="goToCart" size="sm">
                            <font-awesome-layers>
                                <font-awesome-icon icon="fa-cart-shopping" size="2x"/>
                                <font-awesome-layers-text counter transform="shrink-3" :value="ticketsAmount" position="top-left"/>
                            </font-awesome-layers>
                        </mdb-btn>
                    </mdb-nav-item>

                    <mdb-dropdown tag="li" class="nav-item my-auto">
                        <mdb-dropdown-toggle class="ms-auto text-white" tag="a"  navLink slot="toggle" waves-fixed>
                            <a v-if="$i18n.locale === 'es' || $i18n.locale == null" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='es'; $store.commit('setIdioma', this.$i18n.locale); reload() }"><img src="../assets/Spain_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>ES</a>
                            <a v-if="$i18n.locale === 'en'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='en';$store.commit('setIdioma', this.$i18n.locale); reload()}"><img src="../assets/uk_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>EN</a>
                            <a v-if="$i18n.locale === 'fr'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='fr'; $store.commit('setIdioma', this.$i18n.locale); reload()}"><img src="../assets/france_flags.png" style="width: 30px;margin-right:20px;" alt="flag"/>FR</a>
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="ms-auto">
                            <mdb-dropdown-item class="bg-white"><a role="button" @click="() => { $i18n.locale='es'; $store.commit('setIdioma', this.$i18n.locale);  reload()}"><img src="../assets/Spain_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>ES</a></mdb-dropdown-item>
                            <mdb-dropdown-item class="bg-white"><a role="button" @click="() => { $i18n.locale='en';$store.commit('setIdioma', this.$i18n.locale); reload() }"><img src="../assets/uk_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>EN</a></mdb-dropdown-item>
                            <mdb-dropdown-item class="bg-white"><a role="button" @click="() => { $i18n.locale='fr'; $store.commit('setIdioma', this.$i18n.locale);  reload()}"><img src="../assets/france_flags.png" style="width: 30px;margin-right:20px;" alt="flag"/>FR</a></mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>

                </mdb-navbar-nav>
            </mdb-navbar-toggler>

        </mdb-navbar>


        <main class="LayoutDefault__main">

            <slot/>

            <div class="fixed-button justify-content-start big-text mr-4" v-bind:class="{'d-none': ticketsAmount == 0, 'd-lg-flex': mostrarTiempo }" role="button">
                <div class="p-2 bg-white rounded opacity-75 ml-3 mb-3 shadow-lg d-flex"  @click="() => { $router.push('/checkout') }">
                    <p class="ml-2 mr-2 my-auto font-weight-bold" >{{ timeCounter }}</p>
                    <font-awesome-icon class="text-align-start" size="3x" icon="fa-clock mr-auto ml-3 my-auto"></font-awesome-icon>
                </div>
            </div>

        </main>

        <footer class="LayoutDefault__footer text-white unique-color mt-auto pb-0">
            <div class="container py-4">
                <div class="d-flex ml-0 mb-2 pl-0">
                    <a href="/Politica_Privacidad" target="_blank" class="text-white ml-3">{{ $t('default.FOOTER_PRIVACY') }}</a>
                    <a href="/Aviso_Legal" target="_blank" class="text-white ml-3">{{ $t('default.FOOTER_LEGAL') }}</a>
                    <a href="/condiciones-generales" target="_blank" class="text-white ml-3">{{ $t('default.FOOTER_GENERAL') }}</a>
                    <a href="https://alcazabaygibralfaro.malaga.eu/es/visitas/horarios-y-tarifas/" target="_blank" class="text-white ml-3">{{ $t('default.FOOTER_SCHEDULE_AND_RATES') }}</a>
                </div>
                <mdb-row>
                    <mdb-col>
                        <mdb-row class="text-left">
                            <mdb-col col="12" class="py-2">
                                <p>C/ Alcazabilla, 2</p>
                                <p>29012 - Málaga</p>
                                <p>gmpalcazaba@malaga.eu</p>
                            </mdb-col>

                        </mdb-row>
                    </mdb-col>

                    <mdb-col class="my-auto">
                        <img src="@/assets/aytoMalg.png" style="height: 100px;"/>
                    </mdb-col>
                    <!-- <mdb-col>
                        <mdb-row class="text-left">

                            <mdb-col col="12" class="py-2 font-weight-bold text-uppercase">
                                {{ $t('default.FOOTER_TECHNICAL_SUPPORT') }}
                            </mdb-col>

                            <mdb-col col="12" class="py-2">
                                {{ $t('default.FOOTER_CONTACT_PHONE', { phoneNumber: '630 932 987'} ) }}
                            </mdb-col>

                            <mdb-col col="12" class="py-2">
                                {{ $t('default.FOOTER_EMAIL', { email: 'info@castillosypalacios.es'} ) }}
                            </mdb-col>

                        </mdb-row>

                    </mdb-col> -->
                </mdb-row>
            </div>
            <div class="clearfix unique-color-dark py-2 align-middle">
                <span class="my-auto">&copy; Spainheritagenetwork</span>
                <div class="float-right pr-5 pb-0 mb-0">
                    <a href="https://www.facebook.com/alcazabaygibralfaromalaga/"><font-awesome-icon class="text-white mx-2 fa-2x" icon="fa-brands fa-facebook-square"/></a>
                    <a href="https://twitter.com/alcazabamlg"><font-awesome-icon class="text-white mx-2 fa-2x" icon="fa-brands fa-twitter-square"/></a>
                    <a href="https://www.instagram.com/alcazabaygibralfaromalaga/"><font-awesome-icon class="text-white mx-2 fa-2x" icon="fa-brands fa-instagram-square"/></a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import ApiService from '@/services/ApiService';
import moment from 'moment';
export default {
    data(){
        return{
            moment: moment,
            productos: 0,
            countdownID: null,
            // Contamos hasta quince minutos antes de borrar la entrada
            countdownMinutes: 15,
            carritoUuid: null,
            expTime: "15:00:00",
            carritoTime: null,
            timeCounter: null,
            timerId: null,
            loginUrl: process.env.VUE_APP_LOGIN_URL,
            spainhnUrl: process.env.VUE_APP_SPAIN_NETWORK,
            activarLink: false
        }
    },
    methods:{
        triggerTimer( ){
            let $vm = this;
            // $vm.carritoTime = moment.utc(expTime);

            // console.log($vm.carritoTime)

            if( !this.timerId ){

                this.carritoTime = moment.utc( this.$store.getters.getExp ?? 0 );

                // console.log( this.$store.getters.cartStatus )
                this.timerId = setInterval( () => { 
                    let now = moment();
                    // console.log(now)
                    let diffTime = $vm.carritoTime - now;

                    // console.log(diffTime)
                    let duration = moment.duration( diffTime - 1000, 'milliseconds' )

                    // console.log(duration);

                    let minutes = duration.minutes();
                    let seconds = duration.seconds();

                    if( minutes < 10 ){
                        minutes = "0" + minutes;
                    }

                    if(seconds < 10){
                        seconds = "0" + seconds;
                    }
                    
                    $vm.timeCounter = (  minutes + ":" + seconds );

                    if( duration <= 0 ){
                        $vm.timeCounter = "0:00";
                        clearInterval( $vm.timerId );
                        $vm.timerId = null;
                        this.$store.commit('removeAllItems')
                    }
                    else if( this.$store.getters.itemsInCart.length == 0 ){
                        $vm.timeCounter = "0:00";
                        clearInterval( $vm.timerId );
                        $vm.timerId = null;
                    }
                }, 1000 )
            }
        },
        goToCart(){
            this.$router.push('/checkout')
        },
        reload(){
            window.location.reload();
        }
    },
    async mounted(){
        this.triggerTimer();

        this.$i18n.locale = this.$store.getters.getIdioma ?? 'es';

        const result = await ApiService.getVisitas( this.$i18n.locale, 'Alcazaba y Castillo de Gibralfaro', 'tarifa' );

        this.productos = result.data;

        const monumentoId = this.productos.map( p => p.monumentoId).reduce( ( a,b ) => b, null );
        this.$store.commit( 'setMonumentoId', monumentoId )

        this.carritoUuid = this.$store.getters.cartStatus.uuid;
        // console.log(this.carritoUuid);

        // Si ya hay un carrito activo, preguntamos al backend si ya se pagó el carrito
        if( this.carritoUuid ){
            // Dependiendo de la respuesta, en caso de que se obtenga que el producto ya se pagó,
            // entonces se procede a eliminar el id del carrito, para que si se agrega una nueva reserva
            // se cree un nuevo carrito
            let result = await ApiService.getCarrito( this.carritoUuid )
            let carritoInfo = result.data;
            if( carritoInfo.status === true ){
                this.$store.commit('removeAllItems');
            }
        }
        // En caso de que no haya carrito activo, revisamos si hay items guardados y los eliminamos para 
        // que si se agregan nuevos productos, se haga asociado a un carrito nuevo
        else{
            if( this.$store.getters.numberOfTickets ){
                this.$store.commit('removeAllItems')
            }
        }

        if( moment('2024-07-01T12:00:00Z').utc().isSameOrBefore( moment()) ){
            this.activarLink = true;
        }
    },
    computed:{
        itemsInCart(){
            let $vm = this;
            let items = this.$store.getters.itemsInCart;
                        
            $vm.productos = items.length;

            return this.$store.getters.itemsInCart;
        },

        ticketsAmount(){
            let amount = this.$store.getters.numberOfTickets;

            if(amount == 0){
                return 0;
            }
            else{
                return amount;
            }
        },

        productosEnCarrito(){
            return this.productos;
        },
        mostrarTiempo(){
            if( this.$store.getters.getExp ){
                this.triggerTimer( this.$store.getters.getExp )
                return true;
            }
            else{
                return false;
            }
        },

        nuevoItemAgregado(){
            return this.$store.getters.cartAnimation;
        },
    }
}
</script>

<style scoped>
    .fixed-button{
        position: fixed;
        bottom: 10px;
        left: 0px;
        z-index: 10;
    }

  .view{
    background-size: cover;
    height: 100%;
  }

  .title{
    font-weight: 900 !important;
  }

</style>