import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    props: route => ({ query: route.query.home }),
    component: HomeView
  },
  {
    path: '/actividades',
    name: 'Actividades',
    component: () => import("../views/HomeEvents.vue")
  },
  {
    path: '/reservarEntradas',
    name: 'crearReserva',
    component: () => import('../views/ReservacionesView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/confirmacion',
    name: 'confirmacion',
    component: () => import('../views/ConfirmacionCompra.vue')
  },
  {
    path:'/condiciones-generales',
    name: 'CondicionesGenerales',
    component: () => import('../views/CondicionesGenerales.vue')
  },
  {
    path: '/Politica_Privacidad',
    name: 'PoliticaPrivacidad',
    component: () => import('../views/PoliticaPrivacidad.vue')
  },
  {
    path:'/Aviso_Legal',
    name:'avisoLegal',
    component: () => import('../views/AvisoLegal.vue')
  },
  {
    path:'/consultar-entradas',
    name:'ConsultarEntradas',
    component: () => import('../views/ConsultarReservas.vue')
  },
  {
    path:'/informacion-importante',
    name:'InformacionImportante',
    component: () => import('../views/ImportantInformation.vue')
  },
  {
    path:'/informacion-importante/en',
    name:'ImportantInformationEn',
    component: () => import('../views/ImportantInformationEn.vue')
  },
  {
    path:'/informacion-importante/fr',
    name:'ImportantInformationFr',
    component: () => import('../views/ImportantInformationFr.vue')
  },
  {
    path:'/tarifas-horarios',
    name:'TarifasHorarios',
    component: () => import('../views/TarifasHorarios.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: "/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

export default router
